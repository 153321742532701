@use 'src/styles/globals.scss' as style;

.left,
.center,
.right {
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: 1px solid style.$lineColor;
  bottom: 0;
  z-index: 1;
}

.lineText {
  height: 24px;
  position: absolute;
  bottom: 0;
  padding-left: 44px;
  transform-origin: left bottom;
  margin: 0;
  @include style.mainText;
  @include style.smallSize;
  color: style.$secondaryText;
  transform: rotate(-90deg);
}

.center {
  left: 310px;
}

.right {
  left: 620px;
}

@media (max-width: 960px) {
  .left {
    left: calc(100% - 24px);
  }
  .center {
    display: none;
  }
  .right {
    left: 24px;
  }
  .lineText {
    padding-left: 16px;
  }
}
