@use 'src/styles/globals.scss' as style;

.item {
  @include style.headerItem;
  margin-left: 24px;
  img {
    height: 18px;
  }
}

@media (max-width: 1040px) {
  .item {
    margin-left: 8px;
  }
}
