@use 'src/styles/globals.scss' as style;

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  span {
    margin-left: 8px;
    text-align: start;
    @include style.mainText;
    @include style.smallerSize;
    color: style.$secondaryText;
  }
}

.led {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
