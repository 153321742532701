@use 'src/styles/globals.scss' as style;

.container {
  min-height: 80px;
  background: #ffffff;
  outline: 1px solid #cacedb;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}

.iconBlock {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  background: #6ce9a6;
}

.content {
  margin-left: 80px;
  box-sizing: border-box;
  padding: 12px 16px 12px 24px;
  width: 100%;
  height: 100%;
  text-align: start;
  div {
    p {
      margin: 0;
      @include style.mainText;
      @include style.smallSize;
      color: style.$mainText;
      &:last-child {
        margin-top: 4px;
      }
      span {
        font-weight: 700;
      }
    }
  }
}
