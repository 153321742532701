@use '../../styles/globals.scss' as fonts;

.wrapper {
  width: 100%;
  position: relative;
}

.line {
  width: 933px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.container {
  width: 1253px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0 263px;
  position: relative;
}

.mainLogo_first,
.mainLogo_second {
  position: absolute;
  right: 36px;
  bottom: -195px;
}

.mainLogo_first {
  display: none;
}

.title {
  margin: 0 0 0 35px;
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  background: linear-gradient(
    90deg,
    #6b738e 32.02%,
    rgba(107, 115, 142, 0) 71.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: start;
  -webkit-text-stroke: 1px #484b54;
  position: relative;
  z-index: 5;
}

.capsule {
  position: relative;
  z-index: 5;
  height: 274px;
  width: 54px;
  padding: 10px;
  border-radius: 240px;
  background: linear-gradient(180deg, #dcdde3 14.92%, #f2f3f7 85.28%);
  border: 1px solid #ffffff;
  box-shadow: -16px -12px 22px #fffefa,
    8px 4px 32px -3px rgba(195, 205, 223, 0.7);
  border-radius: 240px;
  box-sizing: border-box;
}

.blick {
  position: absolute;
  width: 2px;
  height: 107px;
  left: 13px;
  top: 22px;
  background: #f6fef9;
  filter: blur(2px);
}

.bottomShadow {
  position: absolute;
  width: 42px;
  height: 25px;
  left: calc(50% - 42px / 2 + 2px);
  bottom: -9px;
  background: linear-gradient(180deg, #32d583 0%, #3bfc9c 99.99%, #35e38c 100%);
  filter: blur(48px);
  border-radius: 240px;
}

.center {
  height: 254px;
  width: 34px;
  border-radius: 240px;
  background: linear-gradient(
    180deg,
    #3bfc9c 0%,
    #32d583 14.06%,
    #2eff96 99.99%
  );
  box-shadow: inset 0px 0px 16px rgba(255, 255, 255, 0.55);
  border-radius: 240px;
}

@media (max-width: 1270px) {
  .container {
    width: 97%;
  }
}

@media (max-width: 960px) {
  .line {
    width: 100%;
    left: 0;
  }
  .container {
    width: 100%;
    padding: 0 24px 56px;
    box-sizing: border-box;
  }
}

@media (max-width: 850px) {
  .title {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 640px) {
  .container {
    width: 100%;
    position: relative;
    padding-top: 100px;
    padding: 100px 24px 56px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .mainLogo_second {
    display: none;
  }
  .mainLogo_first {
    display: flex;
    top: 0;
    left: 0;
  }
  .capsule {
    display: none;
  }
  .title {
    margin-left: 0;
  }
}
