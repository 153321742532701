@use '../../styles/globals.scss' as fonts;

.container {
  position: relative;
  z-index: 5;
}

.header {
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: fonts.$mainText;
  border-left: 2px solid #f79009;
  text-align: left;
  padding-left: 7px;
  margin: 0;
  box-sizing: border-box;
  @media (max-width: 600px) {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 960px) {
  .container {
    margin-left: 24px;
    width: calc(100% - 52px);
  }
}

@media (max-width: 600px) {
  .header {
    font-size: 32px;
    line-height: 40px;
  }
  .container {
    padding: 0;
  }
}
