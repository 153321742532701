@use 'src/styles/globals.scss' as style;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: center;
}

.business {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 389px;
  @include style.mainText;
  @include style.mainSize;
  color: style.$darkText;
  padding: 10px 8px;
  border-top: 1px solid #cacedb;
  box-sizing: border-box;
  position: relative;
  .border {
    width: 100%;
    position: absolute;
    border-top: 1px solid #ffffff;
    top: 0;
    left: 0;
  }
  &__name {
    width: 46px;
    font-weight: 700;
  }
  p {
    margin: 0;
    padding-left: 50px;
    font-weight: 400;
  }
  img {
    margin-left: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 1250px) {
  .container {
    margin-left: 20px;
  }
}

@media (max-width: 920px) {
  .container {
    align-self: flex-start;
    margin-left: 0;
  }
  .content {
    margin-bottom: 90px;
  }
}

@media (max-width: 460px) {
  .business,
  .container {
    width: 100%;
  }
  .business p {
    padding-left: 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
