.wrapper {
  background: #f4f6fb;
  width: 100%;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  width: 1216px;
  margin: 0 auto;
  overflow: hidden;
}

.walletPoll {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px;
  // margin-bottom: 112px;
}

@media (max-width: 1280px) {
  .container {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
  }
}

@media (max-width: 920px) {
  .walletPoll {
    flex-direction: column;
    align-items: center;
    margin-top: 55px;
  }
}
