@use 'src/styles/globals.scss' as style;

.description {
  text-align: start;
  margin-top: 24px;
  @include style.mainText;
  @include style.mainSize;
  @include style.position;
  color: style.$mainText;
  margin-bottom: 16px;
}

.elucidation {
  @include style.mainText;
  @include style.mainSize;
  @include style.position;
  color: style.$secondaryText;
}

.successContainer {
  margin: 61px 0;
  @include style.position;
}

.successContent {
  @include style.position;
}

.investMore {
  width: 100%;
  @include style.mainText;
  font-size: 16px;
  line-height: 24px;
  color: #484b54;
  text-align: right;
  &:hover {
    cursor: pointer;
  }
}

.progressBlock {
  @include style.position;
  margin: 61px 0;
}
