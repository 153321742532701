@use '../../styles/globals.scss' as fonts;

.container {
  width: 933px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 144px;
  position: relative;
}

.advantages {
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 5;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 310px;
  font-family: fonts.$Ubuntu;
  font-style: normal;
  color: fonts.$mainText;
  text-align: start;
  padding-right: 24px;
  box-sizing: border-box;
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    width: 310px;
    padding-right: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
  }
  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    p {
      margin: 0;
    }
  }
}

@media (max-width: 960px) {
  .item__title {
    width: 100%;
  }
  .container {
    width: 100%;
  }

  .advantages {
    width: calc(100% - 54px);
    margin-left: 24px;
    justify-content: space-between;
  }
  .item {
    padding-right: 0;
    padding: 0 5px;
  }
}

@media (max-width: 600px) {
  .advantages {
    margin-left: 0;
    margin-top: 0;
    padding: 0 24px;
    flex-direction: column;
  }
  .item {
    margin-top: 32px;
    padding: 0;
    width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }
    &__title {
      padding-bottom: 16px;
    }
  }
  .item_type {
    margin-top: 0;
    &:first-child {
      margin-top: 32px;
    }
  }
}
