@use 'src/styles/globals.scss' as style;

.container {
  width: 723px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
}

.pollTile {
  text-align: start;
  margin: 0;
  @include style.mainText;
  @include style.largeSize;
  font-weight: 700;
  color: style.$mainText;
}

.pollInfo {
  width: calc(100% - 160px);
  margin: 0 80px;
  padding-top: 56px;
  padding-bottom: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.returnMoney {
  margin: 16px 0;
  @include style.mainText;
  @include style.smallerSize;
  text-align: center;
  color: style.$currencyColor;
}

.warning {
  width: 364px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  background: #fee4e2;
  border-radius: 8px;
  align-self: center;
  span {
    margin-left: 8px;
    @include style.mainText;
    @include style.smallerSize;
    color: style.$errorText;
  }
}

.permissionsWrapper {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1280px) {
  .container {
    margin-left: 20px;
  }
}

@media (max-width: 920px) {
  .container {
    margin-left: 0;
    margin-top: 80px;
    width: 100%;
    box-sizing: border-box;
  }
  .pollInfo {
    width: 100%;
    margin: 0;
    justify-content: space-between;
    padding: 40px 0;
  }
}

@media (max-width: 460px) {
  .warning {
    width: 100%;
    box-sizing: border-box;
    span {
      text-align: start;
      margin-left: 8px;
    }
  }
  .permissionsWrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    div:last-child {
      margin-left: 0;
    }
  }
}
