@use 'src/styles/globals.scss' as style;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  isolation: isolate;
  width: 266px;
  background: #ffffff;

  box-shadow: 0px 11px 27px rgba(0, 0, 0, 0.17), 0px 2px 8px rgba(0, 0, 0, 0.14),
    0px 1.12694px 2.75474px rgba(0, 0, 0, 0.08);
  border-radius: 4.86667px;
  position: absolute;
  bottom: 27px;
  left: -64px;
  z-index: 5;
  color: style.$secondaryDark;
  box-sizing: border-box;
}

.header {
  margin: 0;
  @include style.mainText;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.close {
  position: absolute;
  top: 4px;
  right: 4px;
}

.description {
  margin-top: 4px;
  @include style.mainText;
  font-size: 12px;
  line-height: 14px;
}

@media (max-width: 920px) {
  .container {
    left: 0;
  }
}

@media (max-width: 460px) {
  .container {
    width: 100%;
    left: 0;
  }
}
