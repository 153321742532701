@use 'src/styles/globals.scss' as style;

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 77px;
  margin-bottom: 53px;
  position: relative;
  z-index: 5;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  span {
    margin-top: 5px;
    @include style.mainText;
    @include style.smallerSize;
    text-align: right;
    color: style.$mainText;
  }
}

.intupInvest {
  padding: 14px 12px;
  isolation: isolate;
  width: 280px;
  height: 48px;
  background: #ffffff;
  border-radius: 12px;
  border: none;
  outline: none;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06));
}

.sendButton {
  width: 130px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 720px) {
  .container {
    flex-direction: column;
    margin: 48px 0;
  }
  .inputBlock,
  .intupInvest {
    width: 100%;
  }
  .inputBlock {
    align-items: flex-start;
  }
  .sendButton {
    margin-top: 24px;
    width: 100%;
  }
}
