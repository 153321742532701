@use 'src/styles/globals.scss' as style;

@mixin bgBlock {
  box-sizing: border-box;
  border: 1px solid #ffffff;
  box-shadow: 0px 1.7328px 5.19839px rgba(16, 24, 40, 0.1),
    0px 1.7328px 3.46559px rgba(16, 24, 40, 0.06), inset 2px 2px 2px #ffffff;
  backdrop-filter: blur(14px);
  overflow: hidden;
}

.container {
  position: relative;
}

.mainBlock {
  width: 596px;
  height: 559px;
  position: relative;
  @include bgBlock;
  border-radius: 24px;
  padding: 32px 16px 24px;
  box-sizing: border-box;
  position: relative;
  z-index: 5;
  margin-bottom: 10px;
}

.topBlock {
  height: 43.5px;
  width: 100%;
}

.background {
  position: absolute;
  background: url('../../../assets/images/poll/noise.png'),
    rgba(250, 251, 255, 0.22);
  opacity: 0.04;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.headerCenter {
  position: absolute;
  top: -1px;
  left: calc(50% - 91px);
  width: 182px;
  opacity: 0.1;
}

.ellipse {
  position: relative;
  z-index: 15;
  font-family: style.$Ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  text-transform: uppercase;
  color: #cacedb;
}

.indicator {
  @include bgBlock;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: -44px;
  left: calc(50% - 35px);
  background: #f4f6fb;
  border-radius: 50%;
  z-index: 10;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   bottom: 14px;
  //   left: -24px;
  //   width: 30px;
  //   height: 30px;
  //   background: #f1f2f7;
  //   border-top-right-radius: 100%;
  //   box-shadow: 0 -12px 0 0 #F4F6FB;
  // }
  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: 14px;
  //   right: -24px;
  //   width: 30px;
  //   height: 30px;
  //   background: #f1f2f7;
  //   border-top-left-radius: 100%;
  //   box-shadow: 0 -12px 0 0 #F4F6FB;
  // }
}

.content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1280px) {
  .container:last-child {
    margin-top: 92px;
  }
}

@media (max-width: 720px) {
  .mainBlock {
    width: 100%;
    height: 100%;
    padding-top: 52px;
  }
}
