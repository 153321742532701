@use 'src/styles/globals.scss' as style;

.iframe {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page {
  padding: 5px 0;
  overflow: hidden;
}

@media (max-width: 600px) {
  .page > canvas,
  .page > div {
    width: 100% !important;
    height: 100% !important;
  }
}
