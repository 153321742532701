@use '../../styles/globals.scss' as fonts;

@mixin itemBasic {
  display: flex;
  flex-direction: row;
  &:not(:first-child) {
    margin-top: 45px;
  }
  &:hover {
    cursor: pointer;
  }
}

@mixin content {
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 400;
  color: #303239;
  text-align: start;
  width: 779px;
}

.container {
  width: 933px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}

.header {
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: fonts.$mainText;
  border-left: 2px solid #f79009;
  text-align: left;
  padding-left: 7px;
  margin: 0;
}

.questionsWrapper {
  margin-top: 65px;
  margin-left: -66px;
  width: 100%;
}

.questions {
  position: relative;
  padding: 8px 0;
  background: #f4f6fb;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.itemWrapShow {
  @include itemBasic;
}

.itemWrap {
  @include itemBasic;
  &:hover {
    .item {
      background: #fafbfd;
      box-shadow: 3px 3px 8px rgba(16, 24, 40, 0.1),
        2px 2px 6px rgba(16, 24, 40, 0.06), inset 2px 2px 5px #ffffff;
      border-radius: 2px;
      border-color: #fafbfd;
    }
  }
}

.number {
  width: 50px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  text-align: right;
  color: #ffffff;
}

.item {
  position: relative;
  border: 1px solid #cacedb;
  width: 849px;
  border-radius: 2px;
  &__title {
    @include content;
    padding: 15px;
    font-size: 16px;
    line-height: 28px;
  }
  &__description {
    @include content;
    padding: 10px 15px 15px;
    font-size: 14px;
    line-height: 24px;
    p,
    ul {
      margin: 0;
    }
  }
}

.arrow {
  position: absolute;
  top: 15px;
  right: 15px;
}

@media (max-width: 1050px) {
  .questionsWrapper {
    margin-left: 0;
  }
}

@media (max-width: 960px) {
  .questionsWrapper {
    margin-top: 32px;
  }
  .container {
    width: 100%;
    padding: 0;
  }
  .item {
    width: calc(100% - 50px);
  }
  .itemWrap,
  .itemWrapShow {
    width: 100%;
  }
  .item {
    &__title,
    &__description {
      width: calc(100% - 72px);
    }
  }

  .header {
    font-size: 32px;
    line-height: 40px;
  }
  .item {
    &__title {
      padding: 16px 24px;
    }
    &__description {
      padding: 0 24px 16px 24px;
    }
    width: 100%;
    border: none;
    border-bottom: 1px solid #cacedb;
  }
  .itemWrap,
  .itemWrapShow {
    &:not(:first-child) {
      margin-top: 0;
    }
  }
  .arrow {
    top: 16px;
    right: 24px;
  }
  .number {
    display: none;
  }
}
