@use 'src/styles/globals.scss' as style;

.container {
  width: 933px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  align-self: flex-end;
  padding-bottom: 32px;
  padding-top: 132px;
  box-sizing: border-box;
  position: relative;
}

.logo,
.linkInfo,
.social {
  @include style.position;
  text-align: left;
}

.logo {
  &:hover {
    cursor: pointer;
  }
  .audit {
    display: block;
    width: 200px;
    height: 70px;
    margin-top: 30px;
  }
}

.socialLinkInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  .linkInfo__item:first-child {
    margin-bottom: 20px;
  }
}

.linkInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 228px;
  align-self: center;
  &__item {
    width: 100%;
    @include style.mainText;
    @include style.smallSize;
    color: #595d67;
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 24px;
    }
    p {
      margin: 0;
    }
    span {
      text-decoration-line: underline;
    }
    a {
      color: #595d67;
    }
    .support {
      height: 24px;
    }
    img {
      margin-left: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.social {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 85px;
}

@media (max-width: 960px) {
  .container {
    width: 100%;
    justify-content: space-evenly;
  }
  .logo,
  .linkInfo,
  .social {
    margin-left: 0;
    margin-bottom: 30px;
    text-align: left;
  }
}

@media (max-width: 750px) {
  .container {
    flex-direction: column;
    padding: 0 24px;
    padding-top: 30px;
  }
  .linkInfo {
    align-self: flex-end;
    margin-top: 25px;
    &__item {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .social {
    width: 100%;
    align-self: center;
    align-items: center;
    margin-top: 34px;
    margin-bottom: 48px;
  }
  .socialLinkInfo {
    align-self: flex-end;
    .linkInfo__item {
      margin: 0;
    }
  }
}
