@use 'src/styles/globals.scss' as style;

.wrapper {
  width: 1216px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}

.container {
  position: relative;
  width: 100%;
  height: 651px;
  padding: 56px;
  box-sizing: border-box;
  background: rgba(250, 251, 255, 0.55);
  box-shadow: 0px 1.7328px 5.19839px rgba(16, 24, 40, 0.1),
    0px 1.7328px 3.46559px rgba(16, 24, 40, 0.06),
    inset 1.7328px 1.7328px 1.7328px #ffffff;
  backdrop-filter: blur(6px);
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 5;
}

.bgText {
  position: absolute;
  font-family: style.$Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 200px;
  line-height: 146px;
  text-transform: uppercase;
  color: #ffffff;
  top: -23px;
  left: 24px;
  z-index: 2;
  @media (max-width: 960px) {
    font-size: 80px;
    line-height: 57px;
  }
  @media (max-width: 400px) {
    left: 0;
  }

  @media (max-width: 370px) {
    font-size: 70px;
    line-height: 50px;
  }
}

.description {
  width: 463px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    margin: 0;
    @include style.mainText;
    @include style.mainSize;
    font-weight: 700;
    color: style.$darkText;
  }
}

.content {
  &__item,
  .text {
    position: relative;
    padding: 16px 0;
    @include style.mainText;
    @include style.smallerSize;
    color: style.$darkText;
    border-bottom: 1px solid #cacedb;
    box-sizing: border-box;
    ul {
      margin: 8px 0;
    }
    span {
      color: #757985;
    }
    &:last-child:not(.text) {
      border: none;
    }
  }
  .text {
    display: inline;
    padding: 0;
    border-bottom: 1px dashed #484b54;
    &:hover {
      cursor: default;
    }
  }
  .text:hover + div {
    display: flex;
  }
}

.tooltip {
  position: absolute;
  display: none;
  padding: 4px 16px;
  width: 293px;
  right: 40px;
  top: -10px;
  background: #575757;
  //opacity: 0.66;
  border-radius: 4px;

  font-family: style.$Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #ffffff;
}

.diagramBlock {
  align-self: center;
  img {
    width: 540px;
  }
}

@media (max-width: 1270px) {
  .wrapper {
    width: 100%;
    padding: 0 24px;
  }
  .container {
    padding: 24px;
  }
}

@media (max-width: 1140px) {
  .container {
    height: 100%;
  }
  .diagramBlock img {
    padding-left: 10px;
    width: 400px;
  }
}

@media (max-width: 1140px) {
  .container {
    flex-direction: column;
  }
  .diagramBlock img {
    padding: 0;
    width: 400px;
  }
}

@media (max-width: 960px) {
  .wrapper {
    padding: 0;
  }
  .description {
    width: 100%;
  }
  .diagramBlock img {
    width: 343px;
  }
  .tooltip {
    right: 0;
  }
}
