@use 'src/styles/globals.scss' as style;

.wrapper {
  width: 100%;
  position: relative;
}

.bgImage {
  position: absolute;
  right: 0;
  top: 0;
}

.header {
  width: 1253px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0;
  overflow: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.headerBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item:not(:first-child),
  .address {
    margin-left: 24px;
  }
  .logicBlock {
    margin-left: 24px;
  }
}

.item,
.address,
.language {
  @include style.headerItem;
  text-decoration: none;
  p {
    white-space: nowrap;
    font-family: style.$Ubuntu;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #303239;
    margin: 0;
    padding: 0 8px;
  }
}

.language {
  p {
    width: 25px;
  }
}

.logicBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoX11 {
  display: none;
  position: relative;
  z-index: 5;
}

@media (max-width: 1270px) {
  .header {
    width: 97%;
  }
}

@media (max-width: 1040px) {
  .item:not(:first-child),
  .address {
    margin-left: 8px;
  }
  .logicBlock {
    margin-left: 8px;
  }
}

@media (max-width: 920px) {
  .header {
    width: 100%;
    padding: 24px 22px 8px;
    box-sizing: border-box;
  }
}

@media (max-width: 720px) {
  .logoX11 {
    display: flex;
    margin-left: 22px;
  }
  .language {
    margin-right: 0;
  }
  .headerBlock {
    .item:not(:first-child), .address {
      margin-left: 8px;
    }
    .logicBlock {
      margin-left: 8px;
    }
  }
  .headerBlock .addressAbs {
    position: absolute;
    margin-left: 24px;
    top: 70px;
    left: 0;
    z-index: 25;
  }
}

@media (max-width: 570px) {

  .logoX11 {
    margin-top: 48px;
  }
  .headerBlock .address {
    position: absolute;
    margin-left: 24px;
    top: 70px;
    left: 0;
    z-index: 25;
  }

}
