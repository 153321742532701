@use 'src/styles/globals.scss' as style;

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:first-child) {
    margin-left: 16px;
  }
  span {
    margin-left: 4px;
    @include style.mainText;
    @include style.smallSize;
    color: style.$darkText;
  }
}

@media (max-width: 960px) {
  .permissions {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 870px) {
  .itemMain {
    &:not(:first-child) {
      margin-left: 0;
    }
  }
}

@media (max-width: 340px) {
  .item span {
    @include style.smallerSize;
  }
}
