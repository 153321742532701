@use '../../../styles/globals.scss' as fonts;

.container {
  width: 100%;
  margin-top: 24px;
}

.dateTitle,
.dateValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-family: fonts.$Ubuntu;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #a1a6b3;
  }
}

.dateValue span {
  font-size: 14px;
  line-height: 24px;
  color: #303239;
}

.percent {
  position: relative;
  width: 100%;
  height: 8px;
  border: 1px solid #232428;
  border-radius: 4px;
  box-sizing: border-box;
}

.percent .progress {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 70%;
  height: 8px;
  border-radius: 10px;
  background: #232428;
  border-radius: 4px;
}
