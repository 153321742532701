@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

$Ubuntu: 'Ubuntu', sans-serif;
$Inter: 'Inter';
$Roboto: 'Roboto';
$lineColor: #dee2ef;
$mainText: #484b54;
$darkText: #303239;
$secondaryDark: #263a54;
$blackText: #232428;
$secondaryText: #a1a6b3;
$currencyColor: #757985;
$successColor: #32d583;
$errorText: #b42318;
$borderColor: #f79009;

@mixin mainText {
  text-align: start;
  font-family: $Ubuntu;
  font-style: normal;
  font-weight: 400;
}

@mixin mainSize {
  font-size: 16px;
  line-height: 28px;
}

@mixin smallSize {
  font-size: 14px;
  line-height: 24px;
}

@mixin smallerSize {
  font-size: 12px;
  line-height: 20px;
}

@mixin largeSize {
  font-size: 20px;
  line-height: 32px;
}

@mixin containerBorder {
  box-sizing: border-box;
  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  box-shadow: 3px 3px 8px rgba(16, 24, 40, 0.1),
    2px 2px 6px rgba(16, 24, 40, 0.06), inset 2px 2px 5px #ffffff;
  border-radius: 4px;
  overflow: hidden;
}

@mixin position {
  position: relative;
  z-index: 5;
}

@mixin headerItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: #eceff5;
  padding: 4px 12px;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
  }
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 2px;
    padding: 1px;
    background: linear-gradient(180deg, #cacedb 0%, #ffffff 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

@mixin additionalPage {
  width: 100%;
  height: 100vh;
  margin: 0;
}

@mixin scroll {
  &::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #616774;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #484b54;
    border-radius: 10px;
  }
}
