@use 'src/styles/globals.scss' as style;

.lightBox {
  transition: opacity 0.1s ease-in-out 0s;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  margin-left: -50vw;
  top: 0;
  left: 50%;
  z-index: 200;
  will-change: opacity;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.modalCard {
  position: relative;
  border-radius: 24px;
  padding: 50px;
  background: #edebed;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.closeButton {
  box-sizing: border-box;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.header {
  align-self: center;
  @include style.mainText;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
  color: style.$borderColor;
}

.providerWrapper {
  width: 100%;
  margin-top: 48px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.providerContainer {
  width: 267px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  gap: 12px;
  transition: background-color 0.2s ease-in-out 0s;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  &:hover {
    cursor: pointer;
  }
  margin-left: 0;
  &:not(:first-child) {
    margin-left: 20px;
    @media (max-width: 680px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.providerIcon {
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  overflow: visible;
  box-shadow: none;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.image {
  width: 45px;
  height: 45px;
}

.provideName {
  padding-left: 12px;
  @include style.mainText;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: style.$darkText;
}

.providerDescription {
  width: 100%;
  font-size: 18px;
  margin: 0.333em 0;
  color: rgb(169, 169, 188);
}

@media (max-width: 680px) {
  .providerWrapper {
    flex-direction: column;
  }
  .header {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 380px) {
  .container {
    padding: 0;
  }
  .modalCard {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
  .providerContainer {
    margin: 4px 0;
  }
}
