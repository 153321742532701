@use '../../styles/globals.scss' as fonts;

.container {
  width: 933px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 144px;
  position: relative;
  padding-top: 52px;
}

.aboutUs {
  margin-top: 56px;
  z-index: 5;
}

.preview {
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: fonts.$mainText;
  text-align: start;
}

.preview.analitic {
  font-family: fonts.$Ubuntu;
  width: 722px;
  margin-top: 56px;
}

.analiticIcons {
  margin-top: 56px;
  display: grid;
  justify-content: flex-start;
  align-content: center;
  grid-template-columns: repeat(auto-fit, minmax(310px, 295px));
  grid-gap: 32px 0;
  width: 620px;
}

.itemIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 56px;
  &__icon {
    width: 40px;
    height: 40px;
  }
  &:last-child {
    .title {
      width: 212px;
    }
  }
  & .title {
    width: 143px;
    padding-left: 16px;
    text-align: start;
    font-family: fonts.$Ubuntu;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: fonts.$mainText;
  }
}

.brush {
  margin-top: 125px;
  display: flex;
  justify-content: flex-start;
  margin-left: -16px;
}

@media (max-width: 960px) {
  .container {
    width: 100%;
  }

  .preview.analitic {
    width: 100%;
  }

  .aboutUs {
    margin-left: 24px;
    margin-top: 32px;
    width: calc(100% - 54px);
  }
  .analiticIcons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
}
