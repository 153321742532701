@use 'src/styles/globals.scss' as style;

.container {
  width: 953px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.error_wrap {
  height: calc(100vh - 506px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include style.mainText;
  color: #303239;
  p {
    font-size: 36px;
    line-height: 120%;
    margin: 0;
  }
}

.not_found_title {
  @include style.mainText;
  font-size: 120px;
  line-height: 120%;
  margin: 0;
}

@media (max-width: 960px) {
  .container {
    width: 100%;
  }
}
