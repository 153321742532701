@use 'src/styles/globals.scss' as style;

.container {
  @include style.position;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 1328px;
  background: #f4f6fb;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: #dee2ef;
  border-radius: 0 16px 16px 0;
}

.market {
  width: 100%;
  margin-top: -1px;
  position: relative;
}

.trading {
  position: absolute;
  width: 100%;
  height: 46px;
  top: 0;
  z-index: 15;
}

.imageBlock {
  margin-top: 90px;
  padding-bottom: 25px;
  width: 1075px;
  align-self: flex-end;
  position: relative;
}

.allInvest {
  position: absolute;
  @include style.mainText;
  left: 45px;
  top: 0;
  p {
    margin: 0;
    font-size: 20px;
    line-height: 32px;
    color: #757985;
  }
  span {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #303239;
  }
}

.marketImage {
  width: 1075px;
}

@media (max-width: 1350px) {
  .container {
    width: 100%;
  }
  .marketImage,
  .imageBlock {
    width: 100%;
  }
}

@media (max-width: 960px) {
  .marketImage {
    width: 100%;
  }
  .imageBlock {
    margin-top: 30px;
    width: 100%;
  }
  .allInvest {
    span {
      font-size: 26px;
      line-height: 30px;
    }
  }
}

@media (max-width: 550px) {
  .allInvest {
    p {
      font-size: 14px;
      line-height: 18px;
    }
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
