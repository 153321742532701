@use '../../styles/globals.scss' as style;

.wrapper {
  padding-top: 155px;
  position: relative;
  // overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.bgPattern {
  position: absolute;
  top: 0;
  left: -111px;
}

.bgText {
  position: absolute;
  top: 123px;
  right: 0;
  font-family: style.$Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 168px;
  line-height: 146px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 2;
}

@media (max-width: 1280px) {
  .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bgPattern {
    display: none;
  }
  .bgText {
    width: 827px;
    transform: rotate(-90deg) translateX(-43%) translateY(270%);
    top: 0;
    right: 0;
    font-weight: 900;
    font-size: 112px;
    line-height: 146px;
  }
}
