@use 'src/styles/globals.scss' as style;

@mixin fonts {
  font-family: style.$Ubuntu;
  font-style: normal;
  text-align: center;
  color: #303239;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -16px;
  margin-top: 16px;
  position: relative;
}

.bgText {
  position: absolute;
  font-family: style.$Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 246.137px;
  line-height: 298px;
  text-transform: uppercase;
  transform-origin: right top;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: start;
  -webkit-text-stroke: 3px #ffffff;
  transform: rotate(-90deg);
  z-index: 0;
}

.step {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 5;

  &__icon {
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f6fb;
  }
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.content {
  margin-left: 25px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &__header {
    @include fonts;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  &__title {
    @include fonts;
    position: relative;
    width: 580px;
    text-align: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    span {
      font-weight: 700;
    }
  }
  &__description {
    @include fonts;
    text-align: start;
    width: 580px;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #757985;
  }
}

.walletLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 12px;
  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      @include fonts;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #f79009;
      text-decoration: none;
      padding-right: 5px;
    }
    &:last-child {
      margin-left: 40px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.arrowTable {
  position: absolute;
  right: 55px;
}

.containerTable {
  margin-top: 15px;
}

@media (max-width: 1050px) {
  .containerTable {
    align-self: flex-end;
    margin-left: 375px;
    margin-top: 64px;
  }
  .step {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .arrowTable {
    transform: matrix(-1, 0, 0, 1, 0, 0) rotate(45deg);
    right: 40px;
    margin-top: 10px;
  }
}

@media (max-width: 720px) {
  .tableWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .containerTable {
    margin-left: 0;
    align-self: flex-end;
  }
  .content {
    width: calc(100% - 47px);
    margin-left: 15px;
  }
  .content__description,
  .content__title {
    width: 100%;
  }
  .arrowTable {
    top: 100%;
  }
}
