@use '../../styles/globals.scss' as fonts;

.container {
  width: 100%;
  margin-top: 24px;
}

.dateTitle,
.dateValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-family: fonts.$Ubuntu;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #a1a6b3;
  }
}

.dateValue span {
  font-size: 14px;
  line-height: 24px;
  color: #303239;
}

.percent {
  position: relative;
  width: 100%;
  height: 8px;
  background: #d1fadf;
  border-radius: 4px;
}

.percent .progress {
  position: absolute;
  top: -2px;
  left: 0;
  width: 70%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(
    270deg,
    #3bfc9c 0%,
    #32d583 14.06%,
    #2eff96 99.99%
  );
  border: 2px solid #f4f6fb;
  box-shadow: inset 0px 0px 16px rgba(255, 255, 255, 0.55);
  border-radius: 240px;
  &.start {
    width: 8px;
    border-radius: 50%;
  }
}
