.logoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.home {
  margin-left: 30px;
  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 1040px) {
  .home {
    margin-right: 8px;
  }
}

@media (max-width: 720px) {
  .logoContainer {
    display: none;
  }
}
