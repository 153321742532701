@use 'src/styles/globals.scss' as style;

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 5;
  span {
    font-family: style.$Ubuntu;
    font-style: normal;
    margin-left: 8px;
  }
  &:nth-child(n + 3) {
    margin-top: 24px;
  }
}

.balance {
  font-weight: 700;
  @include style.largeSize;
  color: style.$darkText;
}

.currency {
  font-weight: 400;
  @include style.mainSize;
  color: style.$currencyColor;
}

.warning {
  margin-left: 16px;
}
