@use 'src/styles/globals.scss' as style;

.container {
  display: flex;
  flex-direction: column;
  width: 389px;
  position: relative;
  z-index: 5;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 16px;
  @include style.containerBorder;
}

.walletTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.title {
  margin-left: 16px;
  @include style.mainText;
  @include style.mainSize;
  color: style.$darkText;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  span {
    margin-left: 8px;
    text-align: start;
    @include style.mainText;
    @include style.smallSize;
    color: style.$secondaryText;
  }
}

.led {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: style.$successColor;
}

.balanceBlock {
  position: relative;
  height: 100%;
  @include style.containerBorder;
  margin-top: 3px;
  padding: 32px 24px;
}

.bgBalance {
  top: 13px;
  left: -195px;
  position: absolute;
  height: 586px;
}

@media (max-width: 460px) {
  .container {
    width: 100%;
    box-sizing: border-box;
  }
  .header,
  .balanceBlock {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .header {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
  }
  .walletTitle {
    order: 2;
    margin-top: 12px;
  }
}
