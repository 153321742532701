@use '../../../styles/globals.scss' as fonts;

.pollCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    font-family: fonts.$Ubuntu;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #595d67;
  }
}

span.count {
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  color: #f79009;
  text-shadow: 2px 2px 0px #ffffff;
  margin-bottom: 8px;
}

@media (max-width: 960px) {
  span.count {
    font-size: 40px;
    line-height: 46px;
  }
}
