@use 'src/styles/globals.scss' as style;

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 112px;
  position: relative;
}

.content,
.contentHidden {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 803px;
}

.contentHidden {
  margin-bottom: 50px;
}

.hiddenBlock {
  position: absolute;
  // z-index: 25;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(244, 246, 251, 1) 15%,
    rgba(244, 246, 251, 0.3) 91%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  align-items: flex-end;
  justify-content: center;
  p {
    margin: 0;
    @include style.mainText;
    @include style.largeSize;
    text-align: center;
    font-weight: 700;
    color: style.$mainText;
  }
}

.block {
  width: 100%;
  height: 50px;
}

.header {
  position: relative;
  h2 {
    margin: 0;
    @include style.mainText;
    @include style.largeSize;
    font-weight: 700;
    color: style.$mainText;
  }
  .border {
    position: absolute;
    width: 42px;
    height: 2px;
    left: 0;
    bottom: 0;
    background: style.$borderColor;
  }
}

.description,
.placeholder {
  margin-top: 24px;
  @include style.mainText;
  @include style.mainSize;
  color: style.$mainText;
}

.placeholder {
  @include style.largeSize;
  color: #cacedb;
}

.point {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 26px;
  margin-left: 16px;
  span {
    margin-left: 8px;
    @include style.mainText;
    @include style.mainSize;
    color: style.$mainText;
    font-weight: 700;
  }
}

@media (max-width: 920px) {
  .container {
    flex-direction: column;
  }
  .content {
    width: 100%;
    margin-bottom: 90px;
  }
  .contentHidden {
    width: 100%;
    margin-bottom: 50px;
  }
  .point {
    align-items: flex-start;
    margin-left: 0;
    order: 2;
  }
  .description {
    &:first-child {
      order: 1;
    }
    &:nth-child(2),
    &:nth-child(3) {
      order: 3;
    }
  }
}

@media (max-width: 500px) {
  .contentHidden {
    width: 100%;
    margin-bottom: 90px;
  }
  .hiddenBlock {
    background: linear-gradient(
      0deg,
      rgba(244, 246, 251, 1) 15%,
      rgba(244, 246, 251, 0.3) 91%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
