@use 'src/styles/globals.scss' as style;

.header {
  position: relative;
  z-index: 5;
  h2 {
    margin: 0;
    @include style.largeSize;
    @include style.mainText;
    font-weight: 700;
    color: style.$mainText;
    text-align: start;
  }
  .border {
    position: absolute;
    width: 42px;
    height: 2px;
    left: 0;
    bottom: 0;
    background: style.$borderColor;
  }
}
