@use 'src/styles/globals.scss' as fonts;

.newsContainer {
  width: 933px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 144px;
}

.newsBlock {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 5;

  width: 933px;
  overflow-x: auto;
  scroll-behavior: smooth;

  @include fonts.scroll;
}

.spinner_wrap {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 50px;
  height: 50px;
  margin-right: 4px;
  display: inline-block;
  border: 3px solid #017de5;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
    border-top-color: #69bafe;
    border-bottom-color: #69bafe;
    border-right-color: transparent;
    border-left-color: transparent;
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 960px) {
  .newsContainer {
    width: 100%;
    padding-bottom: 50px;
  }
  .newsBlock {
    width: calc(100% - 48px);
    margin-left: 24px;
  }
  .newsBlock.scroll {
    width: calc(100% - 48px);
  }
}
