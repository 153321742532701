@use 'src/styles/globals.scss' as style;

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @include style.mainText;
  @include style.mainSize;
  color: style.$currencyColor;
  margin-bottom: 8px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.summary {
  width: 220px;
  text-align: start;
  span {
    font-weight: 700;
  }
}

.item .rate {
  @include style.mainText;
  @include style.smallSize;
  font-weight: 700;
  color: #f79009;
}
