@use '../../styles/globals.scss' as fonts;

.wrapper {
  width: 100%;
  position: relative;
}

.bgLogo {
  position: absolute;
  right: 0;
}

.container {
  width: 933px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 144px 0;
  position: relative;
}

.description {
  padding: 56px 0;
  text-align: justify;
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: fonts.$mainText;
  z-index: 5;
}

.polls {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 5;
  overflow-x: auto;
  scroll-behavior: smooth;
  @include fonts.scroll;
  padding-bottom: 10px;
}

.openPoll,
.closedPoll,
.planPoll {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  width: 619px;
  height: 452px;
  padding: 24px;
  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  box-shadow: 3px 3px 8px rgba(16, 24, 40, 0.1),
    2px 2px 6px rgba(16, 24, 40, 0.06), inset 2px 2px 5px #ffffff;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.openPoll:not(:first-child) {
  margin-left: 10px;
}

.spinner_wrap {
  height: calc(100vh - 506px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 50px;
  height: 50px;
  margin-right: 4px;
  display: inline-block;
  border: 3px solid #69bafe;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
    border-top-color: #69bafe;
    border-bottom-color: #69bafe;
    border-right-color: transparent;
    border-left-color: transparent;
  }
  100% {
    transform: rotate(360deg);
  }
}

.planPoll {
  margin-left: 26px;
  width: 60px;
  padding: 14px;
  .title {
    width: 117px;
    position: absolute;
    left: -50%;
    top: calc(50% - 14px);
    font-family: fonts.$Ubuntu;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #a1a6b3;
    transform: rotate(-90deg);
  }
}

.closedPoll {
  margin-left: 26px;
  width: 117px;
  padding: 14px;
  img {
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
  }
}

.startDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  width: 100%;
  span {
    font-family: fonts.$Ubuntu;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #a1a6b3;
  }
  span.date {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #303239;
  }
}

.pollName {
  position: absolute;
  top: 8px;
  right: 8px;
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #a1a6b3;
  h4 {
    margin: 0;
    font-weight: 400;
  }
}

.pollTitle {
  margin: 0;
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: fonts.$mainText;
}

.pollInfo {
  width: calc(100% - 160px);
  margin: 0 80px;
  padding-top: 56px;
  padding-bottom: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.condition {
  margin-top: 8px;
  text-align: start;
  font-family: fonts.$Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #757985;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonOpen {
  width: 159px;
}

.permissionBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 960px) {
  .container {
    width: 100%;
  }
  .polls {
    margin-top: 24px;
  }
  .description {
    margin-left: 0;
    margin-top: 32px;
    padding: 0 24px;
  }
  .openPoll {
    width: calc(100% - 230px);
  }
  .pollInfo {
    width: 100%;
    margin: 0;
    justify-content: space-evenly;
    padding: 0;
  }
}

@media (max-width: 870px) {
  .permissionBlock {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 960px) {
  .description {
    padding: 0;
  }
  .poolHeader {
    margin-left: -24px;
  }
  .container {
    padding: 115px 24px;
  }
  .bgLogo {
    display: none;
  }
}

@media (max-width: 660px) {
  .openPoll,
  .closedPoll,
  .planPoll {
    height: 386px;
  }
  .openPoll {
    width: 100%;
  }
  .buttonOpen {
    width: 144px;
  }
  .openPoll {
    padding: 24px 16px;
  }
}

@media (max-width: 380px) {
  .buttonOpen {
    width: calc(100% - 152px);
  }
}

@media (max-width: 340px) {
  .buttonOpen {
    width: calc(100% - 135px);
  }
}
